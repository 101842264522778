:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._pointerEvents-auto{pointer-events:auto !important;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._backgroundColor-transparent{background-color:transparent;}
:root ._borderBottomColor-transparent{border-bottom-color:transparent;}
:root ._backgroundColor-surface1{background-color:var(--surface1);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}