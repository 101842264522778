:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}
:root ._alignItems-center{align-items:center;}
:root ._pb-t-space-pad1331704925{padding-bottom:var(--t-space-padding16);}
:root ._flexDirection-column{flex-direction:column;}
:root ._height-40px{height:40px;}
:root ._width-40px{width:40px;}
:root ._background-surface2{background:var(--surface2);}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._justifyContent-center{justify-content:center;}
:root ._alignItems-stretch{align-items:stretch;}