:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._pr-t-space-spa94665589{padding-right:var(--t-space-spacing4);}
:root ._pl-t-space-spa94665589{padding-left:var(--t-space-spacing4);}
:root ._pt-t-space-spa1360334080{padding-top:var(--t-space-spacing12);}
:root ._pb-t-space-spa1360334080{padding-bottom:var(--t-space-spacing12);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pr-_sm_t-space-spa1360334076{padding-right:var(--t-space-spacing16);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pl-_sm_t-space-spa1360334076{padding-left:var(--t-space-spacing16);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pt-_sm_t-space-spa94665589{padding-top:var(--t-space-spacing4);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pb-_sm_t-space-spa94665589{padding-bottom:var(--t-space-spacing4);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._borderBottomWidth-_sm_0px{border-bottom-width:0px;}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._borderBottomStyle-_sm_solid{border-bottom-style:solid;}}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._flexGrow-1{flex-grow:1;}