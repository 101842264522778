:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._alignItems-center{align-items:center;}
:root ._pt-t-space-spa1360334076{padding-top:var(--t-space-spacing16);}
:root ._pr-t-space-spa1360334076{padding-right:var(--t-space-spacing16);}
:root ._pb-t-space-spa1360334076{padding-bottom:var(--t-space-spacing16);}
:root ._pl-t-space-spa1360334076{padding-left:var(--t-space-spacing16);}