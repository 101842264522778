:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._maxWidth-920px{max-width:920px;}
:root ._alignItems-center{align-items:center;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._pointerEvents-auto{pointer-events:auto !important;}
:root ._width-480px{width:480px;}
:root ._pt-t-space-pad95589114{padding-top:var(--t-space-padding8);}
:root ._pr-t-space-pad95589114{padding-right:var(--t-space-padding8);}
:root ._pb-t-space-pad95589114{padding-bottom:var(--t-space-padding8);}
:root ._pl-t-space-pad95589114{padding-left:var(--t-space-padding8);}
:root ._borderTopLeftRadius-t-radius-ro291586420{border-top-left-radius:var(--t-radius-rounded24);}
:root ._borderTopRightRadius-t-radius-ro291586420{border-top-right-radius:var(--t-radius-rounded24);}
:root ._borderBottomRightRadius-t-radius-ro291586420{border-bottom-right-radius:var(--t-radius-rounded24);}
:root ._borderBottomLeftRadius-t-radius-ro291586420{border-bottom-left-radius:var(--t-radius-rounded24);}
:root ._backgroundColor-surface1{background-color:var(--surface1);}
:root ._maxWidth-10037{max-width:100%;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexGrow-1{flex-grow:1;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._cursor-pointer{cursor:pointer;}
:root ._width-500px{width:500px;}