:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._minWidth-320px{min-width:320px;}
:root ._pt-t-space-spa1360334047{padding-top:var(--t-space-spacing24);}
:root ._pb-t-space-spa1360333981{padding-bottom:var(--t-space-spacing48);}
:root ._pr-t-space-spa1360333989{padding-right:var(--t-space-spacing40);}
:root ._pl-t-space-spa1360333989{padding-left:var(--t-space-spacing40);}
@media (max-width: 640px){:root:root:root:root:root:root ._pt-_md_t-space-spa1360334076{padding-top:var(--t-space-spacing16);}}
@media (max-width: 640px){:root:root:root:root:root:root ._pr-_md_t-space-spa1360334076{padding-right:var(--t-space-spacing16);}}
@media (max-width: 640px){:root:root:root:root:root:root ._pb-_md_t-space-spa1360334076{padding-bottom:var(--t-space-spacing16);}}
@media (max-width: 640px){:root:root:root:root:root:root ._pl-_md_t-space-spa1360334076{padding-left:var(--t-space-spacing16);}}
:root ._minWidth-0px{min-width:0px;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._flexWrap-wrap{flex-wrap:wrap;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-spa1360334076{gap:var(--t-space-spacing16);}}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_10037{width:100%;}}