:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._width-fit-content{width:fit-content;}
:root ._borderTopLeftRadius-t-radius-ro291586449{border-top-left-radius:var(--t-radius-rounded16);}
:root ._borderTopRightRadius-t-radius-ro291586449{border-top-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomRightRadius-t-radius-ro291586449{border-bottom-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomLeftRadius-t-radius-ro291586449{border-bottom-left-radius:var(--t-radius-rounded16);}
:root ._pr-t-space-spa94665593{padding-right:var(--t-space-spacing8);}
:root ._pl-t-space-spa94665593{padding-left:var(--t-space-spacing8);}
:root ._pt-t-space-spa94665589{padding-top:var(--t-space-spacing4);}
:root ._pb-t-space-spa94665589{padding-bottom:var(--t-space-spacing4);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
:root ._gap-4px{gap:4px;}
:root ._alignItems-center{align-items:center;}
@media (hover) {:root  ._backgroundColor-0hover-surface2Hov3121676:hover{background-color:var(--surface2Hovered) !important;}}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._gap-t-space-spa94665589{gap:var(--t-space-spacing4);}
:root ._width-10037{width:100%;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexGrow-1{flex-grow:1;}
:root ._justifyContent-flex-end{justify-content:flex-end;}
:root ._gap-10px{gap:10px;}