:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._width-10037{width:100%;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexDirection-column{flex-direction:column;}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._flexGrow-1{flex-grow:1;}
:root ._transition-opacity0--3se464443531{transition:opacity 0.3s ease, transform 0.3s ease;}
:root ._borderLeftWidth-0px{border-left-width:0px;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._pl-0px{padding-left:0px;}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._cursor-pointer{cursor:pointer;}
:root ._cursor-default{cursor:default;}
:root ._display-none{display:none;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._pr-t-space-spa94665589{padding-right:var(--t-space-spacing4);}
:root ._pl-t-space-spa94665589{padding-left:var(--t-space-spacing4);}
:root ._pt-t-space-spa94665591{padding-top:var(--t-space-spacing6);}
:root ._pb-t-space-spa94665591{padding-bottom:var(--t-space-spacing6);}
:root ._width-180px{width:180px;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}